<template>
    <div class="sidebar" id="innr_sidebar" :class="{ active : showSidebar }">
        <router-link to="/client-portal" class="portal" v-if="hasPermission('Client Portal')" v-tooltip="`Configure your client portal for this account`" position="bottom-right">
            <img src="@/assets/images/handsup.svg"><span>Client Portal</span>
        </router-link>
        <div class="area_title">
            <h4>Tool Menu</h4>
        </div>
        <div class="nav_list" v-click-outside="closeAllSubmenu">
            <template v-for="(cat, c) in sidebarCategory" :key="c">
                <div class="menu_wpr">
                    <h5 :class="cat.title" @click="toggleMenu($event)">
                        <span v-tooltip="cat.tooltip" position="bottom-right" :class="{ active: $route.meta && $route.meta.group && cat.title == $route.meta.group }">
                            <img :src="require(`@/assets/images/${cat.icon}.svg`)">
                        </span>
                        <label>{{cat.title}}</label><i class="fas fa-angle-right"></i>
                    </h5>
                    <div class="extend_menu">
                        <ul>
                            <template v-for="(sidebarItem, s) in cat.sidebarItems" :key="s" >
                                <li v-if="hasPermission(sidebarItem.module, sidebarItem.type)" :class="{'disable' : sidebarItem.isUpcoming}">
                                    <template v-if="!sidebarItem.isUpcoming && !sidebarItem.exLink">
                                        <router-link v-if="sidebarItem.name == 'ClientPortalIndex' && user.has_portal" :to="{ name: sidebarItem.name }" :class="{'router-link-active router-link-exact-active': $route.meta && $route.meta.parent && sidebarItem.name == $route.meta.parent.name}" @click="setLastToolRouteName(sidebarItem.name)">
                                            {{ sidebarItem.title }} <label v-if="sidebarItem.isBeta">BETA</label>
                                        </router-link>
                                        <router-link v-else-if="sidebarItem.name != 'ClientPortalIndex'" :to="{ name: sidebarItem.name }" :class="{'router-link-active router-link-exact-active': $route.meta && $route.meta.parent && sidebarItem.name == $route.meta.parent.name}" @click="setLastToolRouteName(sidebarItem.name); closeAllSubmenu();">
                                            {{ sidebarItem.title }} <label v-if="sidebarItem.isBeta">BETA</label>
                                        </router-link>
                                    </template>
                                    <a v-else-if="sidebarItem.exLink && sidebarItem.title == 'Websites'" @click="handleThriveFunnelRedirect()">
                                        {{ sidebarItem.title }} <label>PRO +</label>
                                    </a>
                                    <a v-else-if="sidebarItem.exLink" :href="sidebarItem.exLink" target="_blank">{{ sidebarItem.title }}</a>
                                    <a v-else @click="sidebarItem.info ? showUpcomingMessage(sidebarItem.info) : ''">{{ sidebarItem.title }}<label v-if="sidebarItem.nearing && sidebarItem.isUpcoming">{{sidebarItem.nearing}}</label></a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <!-- <span @click="toggleSidebar($event)" id="toggle_sidebar" :class="{'swing' : showSidebar}"><i class="fas fa-chevron-right"></i></span> -->
</template>

<script>
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    export default {
        name : "Inner Sidebar",

        data () {
            return {
                showSidebar: true,
                checkoutUpcoming: this.user && this.user.checkout_enabled ? false : true,
                sidebarCategory:[
                    {
                        title: 'Coaching',
                        tooltip: 'Access all your coaching tools like courses, habits, workouts and more!',
                        icon: 'heart',
                        sidebarItems: [
                            { title: 'Playbooks', name: 'PlaybookIndex', module: 'Playbooks' },
                            { title: 'Workouts', name: 'WorkoutIndex', isBeta: false, module: 'Workouts' },
                            { title: 'Habits', name: 'HabitTrackingIndex', module: 'Habit Tracking' },
                            { title: 'Client Tracking', name: 'ProgressTrackingIndex',isBeta: true, module: 'Progress Tracking' },
                            { title: 'Quick Pages', name: 'PageIndex', module: 'Pages' },
                        ]
                    },
                    {
                        title: 'Marketing',
                        tooltip: 'Access your marketing tools like email broadcasts, autoresponders and landing pages',
                        icon: 'marketing',
                        sidebarItems: [
                            { title: 'Opt-ins', name: 'OptinIndex', module: 'Opt-ins' },
                            { title: 'Broadcasts', name: 'BroadcastIndex', module: 'Broadcasts' },
                            { title: 'Sequences', name: 'SequenceIndex', module: 'Sequences' },
                            { title: 'Websites', exLink: 'https://pages.onboardme.io', module: 'Integrations', type: 'fit_funnels' },
                        ]
                    },
                    {
                        title: 'Admin',
                        tooltip: 'Access your admin tools like templates, tags and files',
                        icon: 'admin_check',
                        sidebarItems: [
                            { title: 'Client Journeys', name: 'JourneyIndex', module: 'Journeys' },
                            { title: 'Message Templates', name: 'TemplatesIndex', module: 'Templates' },
                            { title: 'Forms', name: 'FormIndex', module: 'Forms' },
                            { title: 'Files', name: 'FileIndex', module: 'Files' },
                            { title: 'Tags', name: 'TagIndex', module: 'Tags' },
                            { title: 'Groups', name: 'GroupIndex', module: 'Groups' },
                            { title: 'Pipelines', name: '', module: 'Pipelines', nearing:'soon', isUpcoming: true, info: 'We are re-imagining pipelines to be easier to use while still being more versatile across Thrive Coach with more global integrations. We hope to have the new version released soon!'},
                        ]
                    },
                ],
            }
        },

        watch: {
            user (user) {
                const vm = this;

                vm.hideMainMenu('coaching');
                vm.hideMainMenu('marketing');
                vm.hideMainMenu('admin');
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                subscription: state => state.billingModule.subscription,
                companyUserPermissions: state => state.authModule.companyUserPermissions,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            vm.checkRoute();
            vm.myEventHandler();

            window.addEventListener("resize", vm.myEventHandler);

            vm.hideMainMenu('coaching');
            vm.hideMainMenu('marketing');
            vm.hideMainMenu('admin');
        },

        unmounted() {
            window.removeEventListener("resize", this.myEventHandler);
        },

        methods: {
            ...mapActions({
                getSubscription: 'billingModule/getSubscription',
                generateLandingPageToken: 'authModule/generateLandingPageToken',
            }),

            ...mapMutations ({
                setLastToolRouteName: 'commonModule/SET_LAST_TOOL_ROUTE_NAME',
            }),

            toggleSidebar(e) {
                const vm = this;
                vm.showSidebar = !vm.showSidebar;
            },

            checkRoute () {
                const vm = this;

                if (vm.$route.name == 'Dashboard') {
                    vm.showSidebar = false;
                }else{
                    vm.showSidebar = true;
                }
            },

            myEventHandler () {
                let screenWidth = window.innerWidth;
                let screenHeight = window.innerHeight;
                let menuMaxHeight = document.querySelectorAll('.extend_menu');

                if (this.$route.name == "SmsCenterIndex" && screenWidth < 1199) {
                    this.showSidebar = false;
                } else if(this.$route.name !== "SmsCenterIndex" && screenWidth < 992) {
                    this.showSidebar = false;
                } else {
                    this.showSidebar = true;
                }

                menuMaxHeight.forEach(function(item){
                    if(screenHeight < 800){
                        item.classList.add('sm');
                    }else{
                        item.classList.remove('sm');
                    }
                });
            },

            showUpcomingMessage (i) {
                Swal.fire(Helper.swalWarningOptions('Coming Soon!', `${i}`))
            },

            handleThriveFunnelRedirect () {
                const vm = this;

                if (vm.subscription.has_funnels) {
                    if (vm.user.landing_account_id) {
                        vm.generateLandingPageToken().then((params) => {
                            if (params) {
                                window.open(`https://app.landingi.com/api/auth/sign-in/thrivecoach?token=${params.token}&timestamp=${params.timestamp}`, '_blank');
                            } else {
                                window.open('https://pages.onboardme.io', '_blank');
                            }
                        });
                    } else {
                        window.open('https://pages.onboardme.io', '_blank');
                    }
                } else {
                    const options = Helper.swalConfirmOptions('', `Please upgrade your subscription to get Unlimited Thrive Funnels (in <a href="/admin-settings?module=billing&tab=plans">billing</a> under subscriptions)`, 'Upgrade');
                    options.preConfirm = function () {
                                              window.location = '/admin-settings?module=billing&tab=plans';

                                              return true;
                                          }

                    Swal.fire(options);
                }
            },

            resetSidebar () {
                const vm = this;

                if (vm.sidebarCategory[1] && vm.sidebarCategory[1].sidebarItems) {
                    vm.sidebarCategory[1].sidebarItems.forEach((item, index) => {
                        if (item.title == 'Checkouts') {
                            vm.sidebarCategory[1].sidebarItems[index].isUpcoming = vm.user && vm.user.checkout_enabled ? false : true;
                        }
                    });
                }
            },

            hasPermission (module, type = null) {
                const vm    = this;
                const m     = module.replaceAll(' ', '-').toLowerCase();

                if (vm.companyUserCan(type ? type : 'view', m)) {
                    return true;
                }

                return false;
            },

            hideMainMenu (name) {
                setTimeout(() => {
                    const menu = document.getElementById(`${name}-menu`);

                    if (menu && menu.innerText) {
                        document.getElementById(`${name}-title`).classList.remove('hide');
                    } else if (document.getElementById(`${name}-title`)) {
                        document.getElementById(`${name}-title`).classList.add('hide');
                    }
                }, 100);
            },

            toggleMenu(ev){
                let parentElm = ev.currentTarget.closest('.menu_wpr');
                let allElm = document.querySelectorAll('.nav_list .menu_wpr')

                if (parentElm.classList.contains('show')){
                    parentElm.classList.remove('show');
                } else {
                    allElm.forEach(function(item){
                        item.classList.remove('show');
                    })
                    parentElm.classList.add('show');
                }
            },
            closeAllSubmenu(){
                let allElm = document.querySelectorAll('.nav_list .menu_wpr')
                allElm.forEach(function(item){
                    item.classList.remove('show');
                })
            }
        },
    }
</script>

<style scoped>
    #toggle_sidebar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #787878;
        font-size: 10px;
        color: #fff;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 87px;
        top: 335px;
        margin-left: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        z-index: 12;
    }

    #toggle_sidebar.swing {
        transform: rotate(-180deg);
    }

    #app.expanded .sidebar h5 span.active {
        background: inherit;
    }

    #app .sidebar h5 span.active {
        background: #e1eeff;
    }

    @media(max-width: 991px){
        #toggle_sidebar{
            top: 50%;
            left: -8px;
        }
        .sidebar ul li a label{
            display: none;
        }
    }
</style>
